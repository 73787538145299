//MUI Colours:
// (possible exports: amber, blue, blueGrey, brown, common, cyan, deepOrange, deepPurple,
// green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow)

import { createTheme } from '@mui/material'

export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#12161a',
            white: '#ffffff',
            charcoal: '#12161a',
            black: '#000000',
            brightGreen: '#01bf71',
            active: '#114431',
            metalGrey: '#232a34',
        },
    },
})
