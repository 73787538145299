export default function Events() {
    return (
        <div className='App-body font-face-special-elite'>
            <div>See us in person at:</div>
            <br />
            <br />
            <a
                href='https://www.comicartfestival.com/'
                target='_blank'
                rel='noreferrer'>
                Lakes International Comic Art Festival
            </a>
        </div>
    )
}
