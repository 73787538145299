import * as React from 'react'
import Button from '@mui/material/Button'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './../../App.css'

export default function FullNavbar() {
    const navigate = useNavigate()

    return (
        <>
            <Stack
                direction={'row'}
                sx={{
                    flexGrow: 1,
                    justifyContent: 'center',
                    marginLeft: '-50px',
                }}>
                <Button
                    key={'home'}
                    onClick={() => navigate('/')}
                    sx={{
                        my: 2,
                        color: 'white',
                        fontSize: 'larger',
                        marginRight: '5px',
                        marginLeft: '5px',
                    }}>
                    <div className='font-face-special-elite'>Home</div>
                </Button>
                <Button
                    key={'gallery'}
                    onClick={() => navigate('/gallery')}
                    sx={{
                        my: 2,
                        color: 'white',
                        fontSize: 'larger',
                        marginRight: '5px',
                        marginLeft: '5px',
                    }}>
                    <div className='font-face-special-elite'>Gallery</div>
                </Button>
                <Button
                    key={'shop'}
                    onClick={() => navigate('/shop')}
                    sx={{
                        my: 2,
                        color: 'white',
                        fontSize: 'larger',
                        marginRight: '5px',
                        marginLeft: '5px',
                    }}>
                    <div className='font-face-special-elite'>Shop</div>
                </Button>
                <Button
                    key={'events'}
                    onClick={() => navigate('/events')}
                    sx={{
                        my: 2,
                        color: 'white',
                        fontSize: 'larger',
                        marginRight: '5px',
                        marginLeft: '5px',
                    }}>
                    <div className='font-face-special-elite'>Events</div>
                </Button>
            </Stack>
        </>
    )
}
