import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import PeakHareLogo from '../../images/Peak Hare Logo.png'
import './../../App.css'
import { useMediaQuery } from 'react-responsive'
import FullNavbar from './FullNavbar'
import SmallNavbar from './SmallNavbar'

export default function Navbar() {
    const smallScreen = useMediaQuery({ query: '(max-width: 540px)' })

    return (
        <div className='App-header'>
            <AppBar>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters>
                        <Box
                            component='img'
                            sx={{
                                display: 'flex',
                                height: 100,
                                width: 100,
                                maxHeight: { xs: 233 },
                                maxWidth: { xs: 350 },
                            }}
                            alt='Peak Hare Collective'
                            src={PeakHareLogo}
                        />
                        {smallScreen ? <SmallNavbar /> : <FullNavbar />}
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    )
}
