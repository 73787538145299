import React from 'react'
import './App.css'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { defaultTheme } from './theme/defaultTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Home from './pages/Home/Home'
import Navbar from './components/navbar/navbar'
import Gallery from './pages/Gallery/Gallery'
import Shop from './pages/Shop/Shop'
import Events from './pages/Events/Events'

function App() {
    const theme = createTheme(defaultTheme)
    return (
        <div className='App'>
            <ThemeProvider theme={theme}>
                <Router>
                    <Navbar />
                    <Routes>
                        <Route path='/' element={<Home />}></Route>
                        <Route path='/gallery' element={<Gallery />}></Route>
                        <Route path='/shop' element={<Shop />}></Route>
                        <Route path='/events' element={<Events />}></Route>
                    </Routes>
                </Router>
            </ThemeProvider>
        </div>
    )
}

export default App
