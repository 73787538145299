import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'

export default function SmallNavbar() {
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const openClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const closeClicked = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const homeClicked = () => {
        closeClicked()
        navigate('/')
    }
    const galleryClicked = () => {
        closeClicked()
        navigate('/gallery')
    }
    const shopClicked = () => {
        closeClicked()
        navigate('/shop')
    }
    const eventsClicked = () => {
        closeClicked()
        navigate('/events')
    }

    return (
        <>
            {open ? (
                <Stack
                    direction={'row'}
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'right',
                    }}>
                    <IconButton onClick={closeClicked}>
                        <CloseIcon className='icons' />
                    </IconButton>
                    <Menu
                        id='menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={closeClicked}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}>
                        <MenuItem onClick={homeClicked}>
                            <Typography
                                sx={{
                                    fontFamily: 'SpecialElite-Regular',
                                    fontSize: 25,
                                }}>
                                Home
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={galleryClicked}>
                            <Typography
                                sx={{
                                    fontFamily: 'SpecialElite-Regular',
                                    fontSize: 25,
                                }}>
                                Gallery
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={shopClicked}>
                            <Typography
                                sx={{
                                    fontFamily: 'SpecialElite-Regular',
                                    fontSize: 25,
                                }}>
                                Shop
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={eventsClicked}>
                            <Typography
                                sx={{
                                    fontFamily: 'SpecialElite-Regular',
                                    fontSize: 25,
                                }}>
                                Events
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Stack>
            ) : (
                <Stack
                    direction={'row'}
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                    }}>
                    <IconButton onClick={openClicked}>
                        <MenuIcon className='icons' />
                    </IconButton>
                </Stack>
            )}
        </>
    )
}
