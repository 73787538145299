import './../../App.css'

export default function Home() {
    return (
        <>
            <div className='App-body font-face-special-elite'>
                <div className='title'>Peak Hare Collective</div>
                <br />
                <br />
                <div>The creative projects of a small group of artists</div>
                <div> and writers in North West England.</div>
            </div>
        </>
    )
}
