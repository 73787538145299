import { Box, Grid } from '@mui/material'
import galleryImage from '../../images/gallery.jpg'
export default function Gallery() {
    return (
        <>
            <div className='App-body font-face-special-elite'>
                <Grid container spacing={0} justifyContent='center'>
                    <br />
                    <br />
                    <div>Selection of book covers and artworks</div>
                    <Box
                        component='img'
                        sx={{
                            display: 'flex',
                            height: 847,
                            width: 1200,
                        }}
                        alt='Gallery'
                        src={galleryImage}
                    />
                </Grid>
            </div>
        </>
    )
}
